<template>
  <div class="flex-column" >
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$basicSystemConfigYhglSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;"></base-search>
        <!-- <el-checkbox size="mini" style='margin-left:15px;' @change='tableKey=tableKey+1' v-model="showDescription">Id/描述</el-checkbox> -->
      </div>
    </sticky>
    <div class="app-container flex-item">
      <el-row :gutter="8" class="fh">
        <el-col :span="4" class="fh ls-border">
          <el-card shadow="never" class="body-small fh" style="overflow: auto">
            <div slot="header" class="clearfix">
              <el-button type="text" style="padding: 0 11px" @click="getAllUsers">全部用户>></el-button>
            </div>
            <el-tree :data="orgsTree" :expand-on-click-node="false" default-expand-all :props="defaultProps"
              @node-click="handleNodeClick"></el-tree>
          </el-card>
        </el-col>
        <el-col :span="20" class="fh">
          <div class="bg-white fh">
            <el-table ref="mainTable" :key='tableKey' :data="list"  border fit
              highlight-current-row style="width: 100%;" height="calc(100% - 52px)" @row-click="rowClick" @selection-change="handleSelectionChange">
              <el-table-column align="center" type="selection" width="55"> </el-table-column>
              <el-table-column :label="'Id'" v-if="showDescription" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{scope.row.id}}</span>
                </template>
              </el-table-column>
              <el-table-column :label="'用户名'" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="link-type" @click="handleUpdate(scope.row)">{{scope.row.account}}</span>
                </template>
              </el-table-column>
              <el-table-column :label="'姓名'">
                <template slot-scope="scope">
                  <span>{{scope.row.name}}</span>
                </template>
              </el-table-column>
              <el-table-column :label="'所属机构'" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{scope.row.organizations}}</span>
                </template>
              </el-table-column>
              <el-table-column :label="'手机号'">
                <template slot-scope="scope">
                  <span>{{scope.row.tel}}</span>
                </template>
              </el-table-column>
              <el-table-column v-if='showDescription' :label="'描述'" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span style='color:red;'>{{scope.row.description}}</span>
                </template>
              </el-table-column>
              <!-- <el-table-column class-name="status-col" :label="'状态'" width="80">
                <template slot-scope="scope">
                  <span :class="scope.row.status | statusFilter">{{$store.state.basicState.find(u =>u.dtCode ==
                    scope.row.status).name}}</span>
                </template>
              </el-table-column> -->
              <el-table-column align="center" :label="'操作'" class-name="small-padding fixed-width" width="180">
                <template slot-scope="scope">
                  <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">编辑</el-button>
                  <!-- <el-button v-if="scope.row.status==0" size="mini" type="danger" @click="handleModifyStatus(scope.row,1)">停用</el-button> -->
                </template>
              </el-table-column>
            </el-table>
            <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"   @pagination="handleCurrentChange" />
          </div>
        </el-col>
      </el-row>
      <!-- 第三方信息 -->
      <el-dialog class="dialog-mini" width="80%" v-el-drag-dialog :title="'第三方信息'" :visible.sync="diaMes"  v-loading="$store.state.listLoading">
        <el-table ref="diaTable"  border :data="diaMesData" style="width: 100%" height="500"  @selection-change="selThirdMesData">
          <el-table-column width="55" type="selection"    align="center" > </el-table-column>
          <el-table-column label="用户名" show-overflow-tooltip align="center" min-width="160px" v-if="true">
            <template slot-scope="scope">
              <span> {{ scope.row.account }} </span>
            </template>
          </el-table-column>
          <el-table-column label="用户名" show-overflow-tooltip align="center" min-width="160px" v-if="true">
            <template slot-scope="scope">
              <span> {{ scope.row.userName }} </span>
            </template>
          </el-table-column>
          <el-table-column label="电话" show-overflow-tooltip align="center" min-width="160px" v-if="true">
            <template slot-scope="scope">
              <span> {{ scope.row.telePhone }} </span>
            </template>
          </el-table-column>
          <el-table-column label="消息类型" show-overflow-tooltip align="center" min-width="160px" v-if="true">
            <template slot-scope="scope">
               <select-type  class="search_position" typeId="SYS_ThirdType"  v-model="scope.row.thirdType"  :isEdit="false" :placeholder="'消息类型'"  ></select-type>
            </template>
          </el-table-column>
          <el-table-column label="关联ID" show-overflow-tooltip align="center" min-width="160px" v-if="true">
            <template slot-scope="scope">
              <span> {{ scope.row.thirdRelatedId }} </span>
            </template>
          </el-table-column>
          <el-table-column label="有效标志" show-overflow-tooltip align="center" min-width="160px" v-if="true">
            <template slot-scope="scope">
              <span  :class="scope.row.enable?'color-success':'color-danger'">  {{ filterenable(scope.row.enable)}} </span>
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer">
          <el-button size="mini" type="success" @click="addMess">添加</el-button>
          <el-button   size="mini" type="danger" @click="delMess">删除</el-button>
          <el-button   size="mini" type="danger" @click="diaMes=false">关闭</el-button>
        </div>
      </el-dialog>
      <!-- 批量添加 -->
       <el-dialog class="dialog-mini" width="60%" v-el-drag-dialog :title="'批量添加'" :visible.sync="diaAddMes"  v-loading="$store.state.listLoading">
         <el-table ref="addDiaTable"  border :data="multipleThirdMesDatas" style="width: 100%" height="500">
            <el-table-column label="用户名" show-overflow-tooltip align="center" min-width="160px" v-if="true">
            <template slot-scope="scope">
              <span> {{ scope.row.account }} </span>
            </template>
          </el-table-column>
          <el-table-column label="消息类型" show-overflow-tooltip align="center" min-width="160px" v-if="true">
            <template slot-scope="scope">
               <select-type  class="search_position" typeId="SYS_ThirdType"  size="mini" v-model="scope.row.thirdType"  :isEdit="true" :placeholder="'消息类型'" ></select-type>
            </template>
          </el-table-column>
          <el-table-column label="关联ID" show-overflow-tooltip align="center" min-width="160px" v-if="true">
            <template slot-scope="scope">
              <el-input v-model="scope.row.thirdRelatedId " maxlength="50" show-word-limit></el-input>
            </template>
          </el-table-column>
           <el-table-column label="电话" show-overflow-tooltip align="center" min-width="160px" v-if="true">
            <template slot-scope="scope">
              <el-input v-model=" scope.row.telePhone " maxlength="50" show-word-limit></el-input>
            </template>
          </el-table-column>
        </el-table>
         <div slot="footer">
            <el-button   size="mini" type="danger" @click="diaAddMes=false">取消</el-button>
            <el-button size="mini" type="success" @click="addMesData">添加</el-button>
        </div>
       </el-dialog>
      <el-dialog class="dialog-mini" width="500px" v-el-drag-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
        <el-form :rules="rules" ref="dataForm" :model="temp" label-position="right" label-width="100px">
          <!-- <el-form-item size="small" :label="'Id'" prop="id" v-show="dialogStatus=='update'">
            <el-input v-model="temp.id" :disabled="true" size="small" placeholder="系统自动处理"></el-input>
          </el-form-item> -->
          <el-form-item size="small" :label="'用户名'" prop="account">
            <el-input v-model="temp.account" maxlength="50" show-word-limit :disabled=isAdmin></el-input>
          </el-form-item>
          <el-form-item size="small" prop="name" :label="'姓名'">
            <el-input v-model="temp.name" maxlength="50" show-word-limit :disabled=isAdmin></el-input>
          </el-form-item>
          <el-form-item size="small" prop="tel" :label="'手机号'">
            <el-input v-model="temp.tel" maxlength="11" show-word-limit></el-input>
          </el-form-item>
           <el-form-item size="small" :label="'密码'">
            <el-input v-model="temp.password" maxlength="50" show-word-limit :placeholder="dialogStatus=='update'?'如果为空则不修改密码':'如果为空则默认与账号相同'"></el-input>
          </el-form-item>
          <el-form-item size="small" :label="'状态'">
            <el-select class="filter-item" v-model="temp.status" placeholder="请选择状态" :disabled=isAdmin>
              <el-option v-for="item in  statusOptions" :key="item.key" :label="item.display_name" :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item size="small" prop="organizationIds" style="height:auto" :label="'所属机构'">
            <treeselect v-if="dialogFormVisible" :options="orgsTrees" :default-expand-level="3" :multiple="true" :flat="true" :open-on-click="true" :open-on-focus="true" :clear-on-select="true" v-model="selectOrgs"  placeholder="请选择所属机构"  :disabled=isAdmin></treeselect>
          </el-form-item>
          <el-form-item size="small" :label="'描述'">
            <el-input type="textarea" maxlength="250" show-word-limit :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入描述" v-model="temp.description" :disabled=isAdmin>
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
          <el-button size="mini" v-if="dialogStatus=='create'" type="primary" @click="createData">确认</el-button>
          <el-button size="mini" v-else type="primary" @click="updateData">确认</el-button>
        </div>
      </el-dialog>
      <el-dialog width="516px"  class="dialog-mini body-small" v-el-drag-dialog :title="'分配角色'" :visible.sync="dialogRoleVisible">
        <el-form ref="rolesForm" size="small" v-if="dialogRoleVisible" label-position="left">
          <el-form-item>
            <select-roles  :roles="selectRoles" :isUnLoadGroupList="true" :userNames="selectRoleNames" v-on:roles-change="rolesChange"></select-roles>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button size="mini" @click="dialogRoleVisible = false">取消</el-button>
          <el-button size="mini" type="primary" @click="acceRole">确认</el-button>
        </div>
      </el-dialog>
    </div>
  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import {listToTreeSelect} from '@/utils'
  import * as accsssObjs from '@/api/accessObjs'
  import * as users from '@/api/users'
  import * as apiRoles from '@/api/roles'
  import * as login from '@/api/login'
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import SelectRoles from '@/components/SelectRoles'
  import Pagination from '@/components/Pagination'
  import elDragDialog from '@/directive/el-dragDialog'
  import extend from "@/extensions/delRows.js"
  import * as basicConfiguration from '@/api/basicConfiguration/basicConfiguration' //基础配置
  
  import SelectType from '@/components/SelectType'
  export default {
    name: 'user',
    components: {
      Sticky,
      Treeselect,
      SelectRoles,
      Pagination,
      SelectType,
    },
    mixins: [extend],
    directives: {
      waves,
      elDragDialog
    },
    data() {
      // 验证手机 
      let checkPhone = (rule, value, callback) => {
        let reg = /^1[345789]\d{9}$/
        if (!reg.test(value)) {
          callback(new Error('请输入11位手机号'))
        } else {
          callback()
        }
      }
      return {
        diaAddMes:false,
        multipleThirdMesData:[],
        multipleThirdMesDatas:[],
        diaMesData:[],
        diaMes:false,
        defaultProps: { // tree配置项
          children: 'children',
          label: 'label'
        },
        multipleSelection: [], // 列表checkbox选中的值
        tableKey: 0,
        list: [],
        total: 0,
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          orgId: '',
          key: undefined
        },
        apps: [],
        statusOptions: [{
          key: 1,
          display_name: '停用'
        },
        {
          key: 0,
          display_name: '正常'
        }
        ],
        showDescription: false,
        orgs: [], // 用户可访问到的组织列表
        // orgsTree: [], // 用户可访问到的所有机构组成的树
        orgsTrees:[],
        orgsTreeData:[],
        selectRoles: [], // 用户分配的角色
        selectRoleNames: '',
        temp: {
          id: undefined,
          description: '',
          organizations: '',
          organizationIds: '',
          account: '',
          name: '',
          tel:'',
          password: '',
          status: 0
        },
        dialogFormVisible: false,
        dialogStatus: '',
        textMap: {
          update: '编辑',
          create: '添加'
        },
        dialogRoleVisible: false, // 分配角色对话框
        rules: {
          account: [{
            required: true,
            message: '用户名不能为空',
            trigger: 'blur'
          }],
          name: [{
            required: true,
            message: '姓名不能为空',
            trigger: 'blur'
          }],
          // tel: [{
          //   required: true,
          //   message: '手机号不能为空',
          //   trigger: 'blur'
          // }],
          tel: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { type: 'number', validator: checkPhone, message: '请输入11位有效手机号号码', trigger: ['blur', 'change'] }
          ],
          organizationIds:[{
            required: true,
            message: '请分配所属机构',
            trigger: 'blur'
          }]
        },
        mesRules:{
         thirdType: [{  required: true,  message: '消息类型不能为空',  trigger: 'blur'  }],
         thirdRelatedId: [{  required: true,  message: '关联ID不能为空',  trigger: 'blur'  }],
         telePhone: [{  required: true,  message: '电话不能为空',  trigger: 'blur'  }]
        },
        downloadLoading: false,
        isAdmin:false,//是否是admin账户
      }
    },
    computed: {
      orgsTree(){
        return this.$store.state.allInstitution
      },
      selectOrgs: {
        get: function() {
           if (this.dialogStatus === 'update') {
            if(this.temp.organizationIds && this.temp.organizationIds.split(',').length>0){
                 return this.temp.organizationIds && this.temp.organizationIds.split(',')
            } else{
              return []
            }
          } else {
            return []
          }
        },
        set: function(v) {
          var _this = this
          _this.temp.organizationIds = v.length > 0 && v.join(',') || ''
          var organizations = _this.orgsTreeData.filter((val) => {
            return _this.temp.organizationIds.indexOf(val.id) >= 0
          }).map(u => u.label)
          this.temp.organizations = organizations.join(',')
        }
      },
      //有效标志的过滤
    filterenable(){
      return function (stockType) { 
        if( this.$store.state.enable.filter(u=>u.dtCode==stockType).length>0){
          return   this.$store.state.enable.filter(u=>u.dtCode== stockType)[0].name
        }else{
          return ''
        }
      }
    },
    },
    filters: {
      statusFilter(status) {
        var res = 'color-success'
        switch (status) {
          case 1:
            res = 'color-danger'
            break
          default:
            break
        }
        return res
      }
    },
    created() {
    },
    activated() {
      this.getList()
    },
    mounted() {
    //  this. getOrg()
   this.getLoadUsersOfTenant()
    },
    methods: {
      getLoadUsersOfTenant(){
        basicDataApi.GetLoadUsersOfTenant().then((res)=>{
         if(res.result.length>0){
          res.result.forEach((item)=>{
            item.value= item.id
            item.label =item.name
          })
          this.orgsTrees = listToTreeSelect(JSON.parse(JSON.stringify(res.result)))
          this. orgsTreeData =res.result 
        }
        }).catch(()=>{

        })
      },
      getOrg(){
        var _this = this // 记录vuecomponent
        login.getOrgs().then(response => {
          _this.orgs = response.result.map(function(item) {
            return {
              id: item.id,
              label: item.name,
              parentId: item.parentId || null
            }
          })
          var orgstmp = JSON.parse(JSON.stringify(_this.orgs))
          _this.orgsTree = listToTreeSelect(orgstmp)
        })

      },
      selThirdMesData(val){
        this.multipleThirdMesData=val
      },
      addMess(){
        if(this.multipleThirdMesData.length<=0){
          this.multipleThirdMesDatas= JSON.parse(JSON.stringify(this.multipleSelection)); 
          this.multipleThirdMesDatas.forEach((item)=>{
              item.userId=item.id
            })
        }else{
           this.multipleThirdMesDatas= JSON.parse(JSON.stringify(this.multipleThirdMesData));
            this.multipleThirdMesDatas.forEach((item)=>{
              item.thirdType=''  
              item.thirdRelatedId =''
              item.telePhone=''
            })
        }
        this.diaAddMes=true
      },
      addMesData(){
        let a=true;//用于标识 类型
        let b=true;//用来标识关联ID
        let flg=true;//是否可以提交
        this.multipleThirdMesDatas.forEach((item)=>{
          if(item.thirdType==''){
            a=false
            flg=false
          }
          if(item.thirdRelatedId==''){
            b=false
            flg=false
          }
        })
        if(!a){
          this.$message.error("请选择消息类型")
          flg=false
          return 
        }
        if(!b){
          this.$message.error("请输入关联ID")
          flg=false
          return 
        }
        if(flg){
          this.$store.commit("updataListLoading",true)
          basicConfiguration.addThirdPartyMes(this.multipleThirdMesDatas).then((res)=>{
            if(res.code=='200'){
              this.$notify({
                title: '成功',
                message: '添加成功',
                type: 'success',
                duration: 2000
              })
               this.diaAddMes=false
               this.getDiaData()
                this.$store.commit("updataListLoading",false)
            }else{
              this.$notify({
                title: '失败',
                message: '添加失败',
                type: 'error',
                duration: 2000
              })
              this.$store.commit("updataListLoading",false)
            }
          }).catch(()=>{
             this.$notify({
                title: '失败',
                message: '添加失败',
                type: 'error',
                duration: 2000
              })
            this.$store.commit("updataListLoading",false)
          })
        }
      },
      delMess(){
          if(this.multipleThirdMesData.length<=0){
            this.$message.error("请选择要批量删除的项")
            return
          }
          this.$confirm(`你确定要删除吗`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					cancelButtonClass: 'message-style',
					type: 'warning'
				}).then(() => {
          this.$store.commit("updataListLoading",true)
          basicConfiguration.delThirdPartyMes(this.multipleThirdMesData).then((res)=>{
            if(res.code=='200'){
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success',
                duration: 2000
              })
               this.getDiaData()
               this.$store.commit("updataListLoading",false)
            }else{
              this.$notify({
                title: '失败',
                message: '删除失败',
                type: 'error',
                duration: 2000
              })
              this.$store.commit("updataListLoading",false)
            }
          }).catch(()=>{
            this.$notify({
              title: '失败',
              message: '删除失败',
              type: 'error',
              duration: 2000
            })
            this.$store.commit("updataListLoading",false)
          })
        })
         
      },
      rowClick(row) {
        this.$refs.mainTable.clearSelection()
        this.$refs.mainTable.toggleRowSelection(row)
      },
      handleNodeClick(data) {
        this.listQuery.orgId = data.id
        this.getList()
      },
      getAllUsers() {
        this.listQuery.orgId = ''
        this.getList()
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
      },
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnAdd':
          this.getLoadUsersOfTenant()
            // this.getOrg()
            this.isAdmin = false
            this.handleCreate()
            break
          case 'btnEdit':
          this.getLoadUsersOfTenant()
            if (this.multipleSelection.length !== 1) {
              this.$message({
                message: '只能选中一个进行编辑',
                type: 'error'
              })
              return
            }
            // this.getOrg()
            this.handleUpdate(this.multipleSelection[0])
            break
          case 'btnDel':
            if (this.multipleSelection.length < 1) {
              this.$message({
                message: '至少删除一个',
                type: 'error'
              })
              return
            }
            this.handleDelete(this.multipleSelection)
            break
          case 'btnAccessRole':
            if (this.multipleSelection.length !== 1) {
              this.$message({
                message: '只能选中一个进行编辑',
                type: 'error'
              })
              return
            }
            this.handleAccessRole(this.multipleSelection[0])
            break
            case 'btnThirdPartyMes':
            if (this.multipleSelection.length <=0) {
              this.$message({
                message: '至少选择一个进行维护',
                type: 'error'
              })
              return
            }
           this.getDiaData()
            break
          default:
            break
        }
      },
      getDiaData(){
        this.diaMes=true
        let ids=[]
        this.multipleSelection.forEach((item)=>{
          ids.push(item.id)
        })
        let sub={
          userIds:ids,
          page: 1,
          limit: 999,
          key: ""
        }
        this.$store.commit("updataListLoading",true)
        basicConfiguration.getThirdPartyMes(sub).then((res)=>{
          if(res.code=='200'){
            this.diaMesData=res.data
            this.diaMes=true
            this.$store.commit("updataListLoading",false)
          }else{
            this.$notify({
              title: '失败',
              message: '获取失败',
              type: 'error',
              duration: 2000
            })
            this.$store.commit("updataListLoading",false)
          }
        }).catch(()=>{
          this.$notify({
              title: '失败',
              message: '获取失败',
              type: 'error',
              duration: 2000
            })
            this.$store.commit("updataListLoading",false)
        })
      },
      getList() {
        users.getList(this.listQuery).then(response => {
          if(response.code == '200'){
            this.list = response.data
            this.total = response.count
            // console.log(this.list,this.total,909)
          }else{
            this.$message({
              message:response.message,
              type:'warning'
            })
            this.list = []
          }
          this.$nextTick(() => {
            this.$refs.mainTable.doLayout();
          });
        }).catch(()=>{
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.listQuery.orgId =""
        this.getList()
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      handleModifyStatus(row, status) { // 模拟修改状态
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        row.status = status
      },
      resetTemp() {
        this.temp = {
          id: undefined,
          description: '',
          organizations: '',
          organizationIds: '',
          account: '',
          name: '',
          status: 0
        }
      },
      handleCreate() { // 弹出添加框
        this.resetTemp()
        this.dialogStatus = 'create'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },
      createData() { // 保存提交
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            users.add(this.temp).then((response) => {
              this.temp.id = response.result
              this.list.unshift(this.temp)
              this.dialogFormVisible = false
              this.$notify({
                title: '成功',
                message: '创建成功',
                type: 'success',
                duration: 2000
              })
            })
          }
        })
      },
      handleUpdate(row) { // 弹出编辑框
        if(row.account == "admin"){
          this.isAdmin = true
        }else{
          this.isAdmin = false
        }
        this.getLoadUsersOfTenant()
        this.temp = Object.assign({}, row) // copy obj
        this.dialogStatus = 'update'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },
      updateData() { // 更新提交
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            const tempData = Object.assign({}, this.temp)
            users.update(tempData).then(() => {
              for (const v of this.list) {
                if (v.id === this.temp.id) {
                  const index = this.list.indexOf(v)
                  this.list.splice(index, 1, this.temp)
                  break
                }
              }
              this.dialogFormVisible = false
              this.$notify({
                title: '成功',
                message: '更新成功',
                type: 'success',
                duration: 2000
              })
            })
          }
        })
      },
      handleDelete(rows) { // 多行删除
         this.delrows(users, rows)
      },
      handleAccessRole(row) { // 分配角色
        const _this = this
        this.temp = Object.assign({}, row) // copy obj
        apiRoles.loadForUser(this.temp.id).then(response => {
          _this.dialogRoleStatus = 'update'
          _this.dialogRoleVisible = true
          _this.selectRoles = response.result
          _this.getRoleList()
          _this.$nextTick(() => {
            _this.$refs['rolesForm'].clearValidate()
          })
        })
      },
      // 获取角色
      getRoleList() {
        apiRoles.getList({}).then(response => {
          this.selectRoleNames = [...response.result].filter(x => this.selectRoles.indexOf(x.id) > -1).map(item => item.name || item.account).join(',')
        })
      },
      rolesChange(type, val) {
        if(type === 'Texts'){
          this.selectRoleNames = val
          return
        }
        this.selectRoles = val
      },
      acceRole() {
        accsssObjs.assign({
          type: 'UserRole',
          firstId: this.temp.id,
          secIds: this.selectRoles
        }).then(() => {
          this.dialogRoleVisible = false
          this.$notify({
            title: '成功',
            message: '更新成功',
            type: 'success',
            duration: 2000
          })
        })
      }
    },
    watch:{
      //处理通过tags关闭页面多选不清空的问题
      '$route'(){
        if(this.$store.state.isLeaveTags == true){
          this.multipleSelection = [];
          this.$refs.mainTable.clearSelection()
          this.$store.state.isLeaveTags = !this.$store.state.isLeaveTags
        }
      }
    }
  }

</script>

<style>
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both
  }

  .el-card__header {
    padding: 12px 20px;
  }

  .body-small.dialog-mini .el-dialog__body .el-form {
    padding-right: 0px;
    padding-top: 0px;
  }

</style>
